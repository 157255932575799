import React from 'react'
// import EmailIcon from '@mui/icons-material/Email';
import "./Header.css"

function Header() {
  return (
    <div className="header">
        <a href="/">
          <h1>シンプル<br/>黒板v1.1</h1>
        </a>
    </div>
  )
}

export default Header