import React from 'react'
import "./Board.css"

function Board({fontSize, textInput,backgroundColor, setTextInput, color}) {
    const handleChange = (e) =>{
        setTextInput(e.target.value);
    }
  return (
    <div className="board">
        <textarea style={{fontSize: `${fontSize}px`, backgroundColor: backgroundColor, color:color}} onChange={handleChange} value={textInput}></textarea>
    </div>
  )
}

export default Board