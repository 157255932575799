import { useState } from 'react';
import './App.css';
import Board from './components/board/Board';
import Bottombar from './components/bottombar/Bottombar';
import Header from './components/header/Header';

function App() {
  // テキストの状態管理
  const [textInput, setTextInput] = useState("");
  // 文字サイズの状態管理
  const [fontSize, setFontSize] = useState(50);
  // 背景色の状態管理
  const [backgroundColor, setBackgroundColor] = useState("var(--board-background)");
  // モーダル開閉の状態管理
  const [isModalOpen, setIsModalOpen] = useState(false);
  // 文字色の状態管理
  const [color, setColor] = useState("var(--choke-color-white)");

  return (
    <div className="app">
      <div className="boardarea">
        {/* 黒板エリア */}
        <Board 
        fontSize={fontSize}
        backgroundColor={backgroundColor}
        textInput={textInput}
        setTextInput={setTextInput}
        color={color}
        setColor={setColor}
        />
        <div>
          {/* headerエリア */}
          <Header />
          {/* オプションエリア */}
          <Bottombar 
          fontSize={fontSize} 
          setFontSize={setFontSize} 
          backgroundColor={backgroundColor}
          setBackgroundColor={setBackgroundColor}
          setTextInput={setTextInput}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          color={color}
          setColor={setColor}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
