import React from 'react'
import BottombarOption from './BottombarOption';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TvIcon from '@mui/icons-material/Tv';
import PaletteIcon from '@mui/icons-material/Palette';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import "./Bottombar.css";

function Bottombar( {fontSize, setFontSize,isModalOpen,setIsModalOpen, backgroundColor, setBackgroundColor, setTextInput, color, setColor}) {
    // 文字サイズの拡大
    const increaseFontSize = () =>{
        setFontSize(fontSize + 10);
    }
    // 文字サイズの縮小
    const decreaseFontSize = () => {
        setFontSize(fontSize - 10);
    }
    // モーダルのオープン/クローズ
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    // 色の選択
    const handleColorChange = (color) => {
        setColor(color);
        toggleModal()
      };
    

    // 背景色の切り替え
    const toggleBackgroundColor = () => {
        setBackgroundColor(backgroundColor === "var(--board-background)" ? "white" : "var(--board-background)")

    }
    // テキストの削除
    const deleteText = () => {
        setTextInput("");
    }

  return (
    <div className="bottombar">
        {/* 文字の縮小 */}
        <BottombarOption text="小" Icon={TextDecreaseIcon} onClick={decreaseFontSize}/>

        {/* 文字の拡大 */}
        <BottombarOption text="大" Icon={TextIncreaseIcon} onClick={increaseFontSize}/>

        {/* 色の選択 */}
        <BottombarOption text="文字" Icon={PaletteIcon} onClick={toggleModal}/>

        {isModalOpen && (
        <div className="color-modal">
        {/* モーダルの内容 */}
          <div className="color-option" onClick={() => handleColorChange('var(--choke-color-white)')}>白</div>
          <div className="color-option" onClick={() => handleColorChange('var(--choke-color-black)')}>黒</div>
          <div className="color-option" onClick={() => handleColorChange('var(--choke-color-yellow)')}>黄</div>
          <div className="color-option" onClick={() => handleColorChange('var(--choke-color-pink)')}>桃</div>
        </div>
        )}

        {/* 背景の変更 */}
        <BottombarOption text="背景" Icon={TvIcon} onClick={toggleBackgroundColor}/>

        {/* 文字の削除 */}
        <BottombarOption text="削除" Icon= {DeleteForeverIcon} onClick={deleteText}/>

    </div>
  )
}

export default Bottombar