import React from 'react'
import "./BottombarOption.css"


function BottombarOption({text, Icon, onClick}) {
  return (
    <div className='bottombarOption' onClick={onClick}>
        <Icon />
        <h2>{text}</h2>
    </div>
  )
}

export default BottombarOption